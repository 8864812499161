.feature-section-sub {
  padding: 155px 0;
  text-align: center;
  font-family: "SF Compact Display", sans-serif;
  font-weight: 600;
  font-size: 50px;
  line-height: 1.25;
  color: #2d2d2d;
  max-width: 780px;
  margin: auto;
  @media (max-width: 768px) {
    font-size: 36px;
    padding: 52px 0;
  }
  .join-the-wait-list {
    margin-top: 50px;
  }
}
.feature-section {
  padding-top: 30px;
  .feature-section-row {
    display: flex;
    @media (max-width: 768px) {
      display: block;
    }
    .feature-section-left-col {
      @media (min-width: 576px) {
        //margin-left: calc((100% - 540px) /2  ) !important;
      }
      @media (min-width: 768px) {
        margin-left: calc((100% - 720px) / 2) !important;
      }
      @media (min-width: 992px) {
        margin-left: calc((100% - 960px) / 2) !important;
      }
      @media (min-width: 1200px) {
        margin-left: calc((100% - 1140px) / 2) !important;
      }
      @media (min-width: 1400px) {
        margin-left: calc((100% - 1320px) / 2) !important;
      }

      .mobile-feature-image {
        display: none;
        @media (max-width: 768px) {
          display: block;
          padding: 25px;
        }
        img {
          max-width: 293px;
          width: 100%;
          margin: auto;
        }
      }
      .feature-title {
        font-family: "SF Compact Display", sans-serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 1.25;
        color: #2d2d2d;
        max-width: 348px;
        margin-bottom: 150px;
        margin-top: 20vh;
        @media (max-width: 768px) {
          font-size: 25px;
          max-width: 100%;
          text-align: center;
          margin-bottom: 20px;
          margin-top: 0;
          padding: 0 20px;
        }
      }

      .feature-item {
        margin-left: auto;
        max-width: 370px;
        padding-right: 60px;
        //height: 80vh;
        margin-bottom: 60vh;
        opacity: 0.25;
        transition: all 0.7s;
        &:last-child {
          margin-bottom: 80vh;
          @media (max-width: 768px) {
            margin-bottom: 0;
          }
        }
        &.activeFeature {
          opacity: 1;
        }
        @media (max-width: 768px) {
          opacity: 1;
          text-align: center;
          max-width: 100%;
          padding: 0;
          margin: 0;
        }
        h3 {
          font-family: "SF Compact Display", sans-serif;
          font-weight: 600;
          font-size: 30px;
          color: #2d2d2d;
          @media (max-width: 768px) {
            font-size: 24px;
            padding: 0 15px;
            margin-top: 60px;
            margin-bottom: 25px;
          }
          span {
            display: block;
            margin-bottom: 12px;
            width: 54px;
            height: 3px;
            @media (max-width: 768px) {
              display: none;
            }
          }
        }

        p {
          font-family: "SF Pro Text", sans-serif;
          font-weight: 500;
          font-size: 16px;
          color: #7c7c7c;
          line-height: 1.75;
          @media (max-width: 768px) {
            padding: 0 15px;
            margin-bottom: 30px;
          }
        }
      }
    }
    .feature-section-right-col {
      flex-grow: 1;
      @media (max-width: 768px) {
        display: none;
      }
      .sticky-section {
        //  background: white;
        height: 100vh;
        transition: all 0.4s;
        .feature-img {
          transition: all 0.5s;
          opacity: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &.activeFeature {
            opacity: 1;
          }
          img {
            height: 750px;
            max-width: 100%;
            max-height: 90vh;
            object-fit: cover;
            object-position: center center;
            // border-radius: 20px;
          }
        }
      }
    }
  }
}

.subtext-note {
  font-size: 16px;
  font-weight: 400;
  font-family: "SF Compact Display", sans-serif;
  color: lightgray;
}
