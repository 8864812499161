.privacy_page {
  font-size: 14px;
  h1 {
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 2em;
    margin-bottom: 0.75em;
  }
  h3 {
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 1em;
  }
  p {
  }
  a {
    color: black;
    text-decoration: none;
  }
  .toc {
    li {
      a {
        display: block;
        padding: 3px 0;
      }
    }
  }
  .font-italic {
    font-style: italic;
  }
}
