.app-store-section {
  padding: 0 30px 60px 30px;
  //background: white;
  @media (max-width: 768px) {
    // padding: 52px 0;
  }
  .app-store-section-h {
    max-width: 618px;
    text-align: center;
    font-family: "SF Compact Display", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.25;
    color: #2d2d2d;
    margin: 0 auto 25px auto;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
  .app-store-section-t {
    font-family: "SF Pro Text", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #7c7c7c;
    line-height: 1.75;
    text-align: center;
    max-width: 800px;
    margin: auto auto 65px auto;
    padding: 0 15px;
  }
  .shape-line-1 {
    height: 21px;
    max-width: 755px;
    margin: auto;
    background: #e4e4e4;
    @media (max-width: 768px) {
      max-width: 309px;
    }
  }
  .shape-line-2 {
    height: 21px;
    max-width: 415px;
    margin: 25px auto 65px auto;
    background: #e4e4e4;
    @media (max-width: 768px) {
      max-width: 170px;
    }
  }
  .app-link-con {
    text-align: center;
    .cta-btn {
      display: inline-flex;
      align-items: center;
      padding: 16px 24px;
      font-family: "SF Compact Display", sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #f7f7f7;
      background: #1f1e1e;
      border-radius: 12px;
      text-decoration: none;
      cursor: pointer;
      img {
        max-height: 24px;
        padding-right: 12px;
      }
    }
  }
}
