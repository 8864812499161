@font-face {
  font-family: "SF Pro Display";
  font-weight: 100;
  src: url("SF-Pro-Display-Ultralight.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 200;
  src: url("SF-Pro-Display-Thin.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 300;
  src: url("SF-Pro-Display-Light.otf");
}

@font-face {
  font-family: "SF Pro Display";
  font-weight: 500;
  src: url("SF-Pro-Display-Medium.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 600;
  src: url("SF-Pro-Display-Semibold.woff2") format("woff2"),
    url("SF-Pro-Display-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 700;
  src: url("SF-Pro-Display-Bold.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 800;
  src: url("SF-Pro-Display-Heavy.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 900;
  src: url("SF-Pro-Display-Black.otf");
}

@font-face {
  font-family: "SF Pro Display";
  font-weight: 100;
  font-style: italic;
  src: url("SF-Pro-Display-UltralightItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 200;
  font-style: italic;
  src: url("SF-Pro-Display-ThinItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 300;
  font-style: italic;
  src: url("SF-Pro-Display-LightItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 400;
  font-style: italic;
  src: url("SF-Pro-Display-RegularItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 500;
  font-style: italic;
  src: url("SF-Pro-Display-MediumItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 600;
  font-style: italic;
  src: url("SF-Pro-Display-SemiboldItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 700;
  font-style: italic;
  src: url("SF-Pro-Display-BoldItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 800;
  font-style: italic;
  src: url("SF-Pro-Display-HeavyItalic.otf");
}
@font-face {
  font-family: "SF Pro Display";
  font-weight: 900;
  font-style: italic;
  src: url("SF-Pro-Display-BlackItalic.otf");
}
