@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 100;
  src: url("SF-Compact-Rounded-Ultralight.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 200;
  src: url("SF-Compact-Rounded-Thin.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 300;
  src: url("SF-Compact-Rounded-Light.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 400;
  src: url("SF-Compact-Rounded-Regular.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 500;
  src: url("SF-Compact-Rounded-Medium.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 600;
  src: url("SF-Compact-Rounded-Semibold.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 700;
  src: url("SF-Compact-Rounded-Bold.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 800;
  src: url("SF-Compact-Rounded-Heavy.otf");
}
@font-face {
  font-family: "SF Compact Rounded";
  font-weight: 900;
  src: url("SF-Compact-Rounded-Black.otf");
}
