.join-modal {
  .modal-close-icon {
    height: 32px;
    width: 32px;
    cursor: pointer;
  }
  .modal-header {
    border: 0;
  }

  .tel-field {
    border-radius: 12px;
    border-color: #ebebef;
    background-color: #f5f5f7;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 54px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
      margin-bottom: 15px;
      line-height: 40px;
    }
  }
  .join-submit-btn {
    width: 100%;
    background-color: #222324;
    border-radius: 8px;
    padding: 0;
    text-align: center;
    line-height: 64px;
    border: 0;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 768px) {
      line-height: 40px;
    }
    &:disabled {
      background-color: #ebebef;
    }
  }
}

.modal-backdrop {
  --bs-backdrop-opacity: 0.8;
}

.fade {
  transition: opacity 0.4s linear;
}

.modal {
  --bs-modal-border-radius: 1rem;
  --bs-modal-header-padding: 0.5rem 0.5rem;
}

.join-container {
  text-align: center;
  .join-train-icon {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 0;
    @media (max-width: 768px) {
      width: 80px;
      height: 80px;
    }
  }
  .join-title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    margin: 30px 0;
    @media (max-width: 768px) {
      margin: 15px 0;
      font-size: 24px;
    }
  }
  .join-description {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin: 0 0 40px 0;
    @media (max-width: 768px) {
      margin: 0 0 25px 0;
      font-size: 17px;
      line-height: 24px;
    }
  }
}

.qr-divider {
  border-top: 1px solid #c7c7d1;
  margin-top: 30px;
  margin-bottom: 30px;
}

.qr-sec-container {
  margin-bottom: 20px;
  .join-qr {
    display: block;
    width: 124px;
    height: 124px;
    margin: 0 20px 0 20px;
    @media (max-width: 575px) {
      margin: auto;
    }
  }
  .qr-description {
    text-align: left;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    margin: 0 20px 0 20px;
    padding: 30px 0;
    @media (max-width: 575px) {
      font-size: 14px;
      line-height: 24px;
      padding: 20px 0 0 0;
      text-align: center;
    }
  }
}
