.site-header {
  padding: 17px 0;
  @media (max-width: 768px) {
    // display: none;
  }

  .site-header-left {
    .train-logo {
      display: inline-block;

      img {
        height: auto;
        max-height: 33px;
        width: auto;
        max-width: 100%;
      }
    }
  }

  .site-header-right {
    text-align: right;
    @media (max-width: 768px) {
      // display: none;
    }
    .app-store-logo {
      display: inline-block;

      img {
        height: auto;
        max-height: 48px;
        width: auto;
        max-width: 100%;
        border-radius: 9px;
      }
    }
  }
  .cta-btn {
    display: inline-flex;
    align-items: center;
    padding: 16px 24px;
    font-family: "SF Compact Display", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #f7f7f7;
    background: #1f1e1e;
    border-radius: 12px;
    text-decoration: none;
    cursor: pointer;
    @media (max-width: 768px) {
      padding: 6px 12px;
      font-size: 14px;
      border-radius: 8px;
    }
    img {
      max-height: 24px;
      padding-right: 12px;
      @media (max-width: 768px) {
        padding-right: 12px;
        max-height: 18px;
      }
    }
  }
}
