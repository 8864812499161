.community-section {
  padding: 155px 0 100px 0;
  @media (max-width: 768px) {
    padding: 52px 0 100px 0;
  }

  .community-section-h {
    max-width: 618px;
    text-align: center;
    font-family: "SF Compact Display", sans-serif;
    font-weight: 600;
    font-size: 50px;
    line-height: 1.25;
    color: #2d2d2d;
    margin: 0 auto 25px auto;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }

  .community-section-t {
    font-family: "SF Pro Text", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #7c7c7c;
    line-height: 1.75;
    text-align: center;
    max-width: 800px;
    margin: auto auto 65px auto;
    padding: 0 15px;
  }

  .shape-line-1 {
    height: 21px;
    max-width: 755px;
    margin: auto;
    background: #e4e4e4;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .shape-line-2 {
    height: 21px;
    max-width: 415px;
    margin: 25px auto 65px auto;
    background: #e4e4e4;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.slick-slider {
  text-align: center;
  margin-bottom: 100px;

  &:after {
    content: " ";
    border-top: 50px solid white;
    border-right: 15px solid white;
    border-bottom: 65px solid white;
    border-left: 15px solid white;
    display: block;
    position: absolute;
    width: 100%;
    max-width: 300px;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 40px;
    box-shadow: 0 40px 128px rgba(0, 0, 0, 0.25);
  }

  .slick-list {
    @media (max-width: 768px) {
      z-index: 1;
    }
    .slick-slide {
      transition: all 0.5s;
      transform: scale(0.75);

      img {
        border-radius: 8px;
        height: 650px !important;
        width: 100% !important;
        object-fit: cover;
        object-position: top center;
        transition: all 0.5s;
        max-width: 300px;
        cursor: pointer;
      }

      &.slick-current {
        transform: scale(1);

        img {
          padding: 50px 15px 65px 15px;
          border-radius: 0;
          // height: 416px;
        }
      }
    }
  }
}

.join-the-wait-list {
  text-align: center;
  a {
    display: inline-block;
    padding: 12px 20px;
    min-width: 220px;
    font-family: "SF Compact Display", sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: white;
    background: black;
    border-radius: 12px;
    text-decoration: none;

    svg {
      margin-left: 20px;
    }
  }
}
