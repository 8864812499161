@font-face {
  font-family: "SF Compact Text";
  font-weight: 100;
  src: url("SF-Compact-Text-Ultralight.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 200;
  src: url("SF-Compact-Text-Thin.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 300;
  src: url("SF-Compact-Text-Light.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 400;
  src: url("SF-Compact-Text-Regular.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 500;
  src: url("SF-Compact-Text-Medium.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 600;
  src: url("SF-Compact-Text-Semibold.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 700;
  src: url("SF-Compact-Text-Bold.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 800;
  src: url("SF-Compact-Text-Heavy.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 900;
  src: url("SF-Compact-Text-Black.otf");
}

@font-face {
  font-family: "SF Compact Text";
  font-weight: 100;
  font-style: italic;
  src: url("SF-Compact-Text-UltralightItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 200;
  font-style: italic;
  src: url("SF-Compact-Text-ThinItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 300;
  font-style: italic;
  src: url("SF-Compact-Text-LightItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 400;
  font-style: italic;
  src: url("SF-Compact-Text-RegularItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 500;
  font-style: italic;
  src: url("SF-Compact-Text-MediumItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 600;
  font-style: italic;
  src: url("SF-Compact-Text-SemiboldItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 700;
  font-style: italic;
  src: url("SF-Compact-Text-BoldItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 800;
  font-style: italic;
  src: url("SF-Compact-Text-HeavyItalic.otf");
}
@font-face {
  font-family: "SF Compact Text";
  font-weight: 900;
  font-style: italic;
  src: url("SF-Compact-Text-BlackItalic.otf");
}
