.join-the-team-section {
  padding: 130px 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  @media (max-width: 768px) {
    padding: 90px 0;
    background-attachment: scroll;
  }
  .join-the-team-section-h {
    text-align: center;
    font-family: "SF Compact Display", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 1.25;
    color: white;
    margin: 0 auto 25px auto;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
  .app-link-con {
    text-align: center;
    a {
      display: inline-block;
      padding: 18px 20px;
      min-width: 240px;
      font-family: "SF Compact Display", sans-serif;
      font-weight: 500;
      font-size: 18px;
      color: #2d2d2d;
      background: white;
      border-radius: 12px;
      text-decoration: none;
      svg {
        margin-left: 10px;
      }
    }
  }
}
