@font-face {
  font-family: "SF Pro Text";
  font-weight: 300;
  src: url("SF-Pro-Text-Light.woff2") format("woff2"),
    url("SF-Pro-Text-Light.otf") format("opentype");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  src: url("SF-Pro-Text-Medium.woff2") format("woff2"),
    url("SF-Pro-Text-Medium.otf") format("opentype");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  src: url("SF-Pro-Text-Semibold.otf");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 700;
  src: url("SF-Pro-Text-Bold.otf");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 800;
  src: url("SF-Pro-Text-Heavy.otf");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 300;
  font-style: italic;
  src: url("SF-Pro-Text-LightItalic.otf");
}

@font-face {
  font-family: "SF Pro Text";
  font-weight: 500;
  font-style: italic;
  src: url("SF-Pro-Text-MediumItalic.otf");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 600;
  font-style: italic;
  src: url("SF-Pro-Text-SemiboldItalic.otf");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 700;
  font-style: italic;
  src: url("SF-Pro-Text-BoldItalic.otf");
}
@font-face {
  font-family: "SF Pro Text";
  font-weight: 800;
  font-style: italic;
  src: url("SF-Pro-Text-HeavyItalic.otf");
}
