@font-face {
  font-family: "SF Compact Display";
  font-weight: 100;
  src: url("SF-Compact-Display-Ultralight.woff2") format("woff2"),
    url("SF-Compact-Display-Ultralight.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 200;
  src: url("SF-Compact-Display-Thin.woff2") format("woff2"),
    url("SF-Compact-Display-Thin.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 300;
  src: url("SF-Compact-Display-Light.woff2") format("woff2"),
    url("SF-Compact-Display-Light.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 400;
  src: url("SF-Compact-Display-Regular.woff2") format("woff2"),
    url("SF-Compact-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 500;
  src: url("SF-Compact-Display-Medium.woff2") format("woff2"),
    url("SF-Compact-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 600;
  src: url("SF-Compact-Display-Semibold.woff2") format("woff2"),
    url("SF-Compact-Display-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 700;
  src: url("SF-Compact-Display-Bold.woff2") format("woff2"),
    url("SF-Compact-Display-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 800;
  src: url("SF-Compact-Display-Heavy.woff2") format("woff2"),
    url("SF-Compact-Display-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "SF Compact Display";
  font-weight: 900;
  src: url("SF-Compact-Display-Black.woff2") format("woff2"),
    url("SF-Compact-Display-Black.otf") format("opentype");
}
